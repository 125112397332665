.stepper-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.stepper-item {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 14.3333%;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
}

.stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
}

.stepper-item.active {
    font-weight: bold;
}

.stepper-item.completed .step-counter {
    background: rgb(29, 101, 244);
    background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
    color: #ffffff;
}

.stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid rgb(29, 101, 244);
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
}


.stepper-item.active::before,
.stepper-item.completed::before {

    border-bottom: 2px solid rgb(29, 101, 244);

}

.stepper-item:first-child::before {
    content: none;
}

.stepper-item:last-child::after {
    content: none;
}