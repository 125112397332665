.logo {
	width: 100%;
	padding: 10px 0px;
}

.logoPdf {
	width: 150px;
	padding: 10px 0px;
}

.contenedor {
	padding: 0px 20px;
}

.principal {
	display: flex;
	flex-wrap: wrap;
	flex: 1;
}

.barra_izquierda {
	height: auto;
	background-color: #fff;
	justify-content: center;
	padding: 0px 0px 0px 0px !important;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin: 20px 0px 10px 20px;
	border-radius: 30px;
	padding-bottom: 10px !important;
}

.barra_derecha {
	height: 100%;
	margin-right: 20px;
	border-radius: 30px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin-top: 20px;
	padding-bottom: 20px;
}

.cont_img_perfil {
	height: 60px;
	width: 60px;
	border-radius: 100px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	padding: 0px 8px;
	float: left;
	overflow: hidden;
}

.avatar {
	margin-top: 5px;
	height: 50px;
	width: 50px;
	border-radius: 100px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.cont_datos_usuario {
	padding: 5px 0px 5px 10px;
}

.cont_datos_usuario h5 {
	margin: 0px;
	font-size: 16px;
	font-weight: 400;
}

.cont_datos_usuario p {
	font-size: 13px;
	margin: 0px;
}

.contenedor_datos_usuario {
	padding: 10px 0px 20px 30px !important;
	border-bottom: 1px solid #eee;
}

.linea {
	border-bottom: 1px solid #eee;
}

.item {
	width: 100%;
	border: none;
	padding: 8px 15px;
	background-color: transparent;
	text-align: left;
	border-bottom: 1px solid #eee;
	color: #333;
}

.sub-item {
	width: 100%;
	border: none;
	padding: 8px 0px 8px 30px;
	background-color: transparent;
	text-align: left;
	border-bottom: 1px solid #eee;
	color: #333;
}

.item:hover,
.sub-item:hover {
	background-color: #1874f0;
	color: #fff;
}

.barra_izquierda .active {
	background-color: #1874f0;
	color: #fff;
}

.boton_estandar {
	background-color: #1d65f4 !important;
	border-radius: 25px;
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	color: #fff;
	padding: 14px 20px !important;
}

.boton_reload_calendario {
	background-color: #ff8000;
	background: rgba(255, 128, 0, 1);
	border-radius: 25px;
	color: #fff;
	padding: 14px 20px !important;

}

.boton_reload_calendario:hover {
	opacity: 0.8;
	color: #fff;
	background-color: rgba(255, 128, 0, 0.8);
}

.boton_estandar_blanco {
	border-radius: 25px;
	background-color: #fff;
	color: #1d65f4;
	padding: 14px 16px !important;
	max-height: 60px !important;
}

.boton_estandar:hover {
	opacity: 0.8;
	color: #fff;
}

.boton_estandar_blanco:hover {
	opacity: 0.8;
	color: #1d65f4;
}

.contenedorAdmin {
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 20px;
	margin-top: 20px;
	padding-bottom: 20px;
	margin-right: 20px;
}

.SinPadding {
	padding: 0px !important;
	z-index: 1;
}

.NombreIgleCom {
	padding: 0px 0px 0px 15px;
}

.tabla_funciones {
	padding: 0px !important;
	width: 160px !important;
}

.tabla_unica {
	padding: 0px !important;
	width: 80px !important;
}



.selectAtri {
	width: 100% !important;
}

.selectEstado {
	width: 100% !important;
	margin-top: 5px;
}

.error {
	font-size: 15px !important;
	color: red;
}

.cont_img_perfil img {
	width: 135%;
	height: 100%;
	margin-left: -7px;
}

.ImgProducto {
	width: 40px;
	height: 40px;
	background-color: lightgray;
}

.ImgOperaReserva {
	width: 60px;
	height: 60px;
	background-color: lightgray;
}

.img_user img {
	margin-top: -4px;
	width: 25px;
	height: 25px;
	background-color: lightgray;
	border-radius: 30px;
}

.custom-file-input:lang(en)~.custom-file-label::after {
	content: "Subir" !important;
}

.FotoPrev {
	width: 100%;
	height: 164px;
	background-color: #f2f2f2;
	margin-top: 23px;
}

.FotoPrev img,
.ImgProducto img,
.ImgOperaReserva img {
	width: 100%;
	height: auto;
	border-radius: 5px;
}

@supports(object-fit: cover) {

	.FotoPrev img,
	.ImgProducto img,
	.fotoPedido img,
	.ImgOperaReserva img {
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}

.campos_atributos {
	margin: 5px 0px !important;
}

.radio_atributo input {
	height: 15px;
	width: 15px;
	margin: 0px 5px 0px 0px;
}

.radio_atributo label {
	margin-bottom: .1rem !important;
}

.combinacion {
	width: 100%;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	padding: 15px;
	background-color: #fff;
}

.combinacion h6 {
	margin-bottom: 3px;
	font-weight: bold;
}

.combinacion p {
	font-size: 13px;
	margin-bottom: 0px !important;
}

.receta {
	padding: 10px 15px 5px 15px !important;
}

.bola_estado_activo {
	height: 12px;
	width: 12px;
	background-color: #50bd50;
	border-radius: 30px;
}

.bola_estado_desact {
	height: 12px;
	width: 12px;
	background-color: #b7b7b7;
	border-radius: 30px;
}

.tabla_productos td,
.tabla_productos th {
	padding: 15px 5px;
}

.estadisticas {
	height: 400px !important;
	padding: 10px;
}

.campoClient {
	background-color: #fff !important;
	height: 45px !important;
}

.fotoPedido {
	height: 110px;
	width: 110px;
	background-color: lightgray;
}

.pedidoAdmin {
	margin-top: 20px;
}

.tachado {
	text-decoration: line-through;
	color: #999;
}

.CajaInforme {
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-left: #ec6c49 solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInforme1 {
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-left: #27ae60 solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInforme2 {
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-left: #f1c40f solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInforme3 {
	width: 30%;
	height: 80px;
	margin: 10px 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-left: #c0392b solid 5px;
	padding: 15px 3px 5px 15px;
}


.CajaInforme:hover {
	background-color: #ec6c49;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme1:hover {
	background-color: #27ae60;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme2:hover {
	background-color: #f1c40f;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme3:hover {
	background-color: #c0392b;
	color: #fff;
	border-left: #fff solid 5px;
}

.CajaInforme h4,
.CajaInforme1 h4,
.CajaInforme2 h4,
.CajaInforme3 h4,
.CajaInforme1 h3 {
	margin: 0px;
}

.CajaInformeUnico {
	width: 95.5%;
	height: 80px;
	margin: 10px 10px;
	border-left: #ec6c49 solid 5px;
	padding: 15px 3px 5px 15px;
}

.CajaInformeMenor {
	width: 95.5%;
	height: 50px;
	margin: 5px 10px;
	border: 1px solid lightgray;
	border-left: #ec6c49 solid 5px;
	padding: 15px 3px 5px 15px;
}

.header-container {
	display: flex;
	justify-content: space-between;
}

.header-container-principal {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 5px;
}

.header-container-principal h2 {
	margin-right: 5px;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: red !important;
	border: none !important;
	outline-color: none;
	font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	background-color: red !important;
	border: none !important;
	outline-color: none;
	font-weight: bold;

}

.busqueda {
	margin-left: -30px;
}

.titulo_fecha {
	font-size: 20px;
	margin-left: 10px;
}

.botones_escritorio {
	padding: 10px 0px;
}

.botones_escritorio div {
	padding: 10px;
}

.SelectFiltro {
	border-radius: 30px;
	height: 46px;
	border: none;
	padding: 5px 10px !important;
	background-color: #fff;
	font-size: 14px;
	color: red;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.filtroPedidos {
	margin-top: 15px;
}

.datosConfig p {
	margin-bottom: 3px !important;
}

.estados {
	padding: 5px 10px;
	border-radius: 5px;
}

.Rojo {
	background-color: #eb8b8d !important;
}

.Blanco {
	background-color: #ffffff !important;
}

.textRojo {
	color: #ac4345;
	font-weight: bold;
}

.Azul {
	background-color: #88d3e2;
}

.AzulClaro {
	background-color: #2772CE;
}

.VerdeClaro {
	background-color: #84C03B;
}

.Verde {
	background-color: #65c895;
}

.Naranja {
	background-color: #D48C53;
}

.Morado {
	background-color: #9B60B3;
}

.Gris {
	background-color: #c1c1c1;
}

.Gris2 {
	background: #D7D7D7;
}

.Blanco {
	background-color: #ffffff;
	border: 1px solid #eee;
}

.TextBlanco {
	color: #ffffff;
}

.Amarillo {
	background: linear-gradient(36deg, rgb(255, 229, 124) 0%, rgb(255, 204, 0) 100%);
}


.textVerde {
	color: #368d60;
	font-weight: bold;
}

.APROBADO {
	background-color: #65c895;
}

.EXPIRADO {
	background-color: #ac4345
}

.COMPLETADO {
	background: #1874f0;
}

.PENDIENTE {
	background-color: rgb(255, 204, 0);
}

.RECHAZADO {
	background-color: #ac4345
}

.CANCELADO {
	background-color: #ac4345
}

.sinMargen div {
	margin-top: 0px !important;
}

.btnBuscar {
	float: right;
	width: 190px;
	height: 40px;
	margin: 5px 5px 0px 0px !important;
	background-color: #fff !important;
	color: #333;
	font-size: 15px;
}

.coment {
	font-size: 13px;
	color: #858585;
}

.buscarZonas {
	margin-top: -15px;
}

.selectores {
	margin-top: 10px;
}

.impresionPdf {
	padding: 30px 30px 60px 30px;
}

.tabla td,
.tabla th {
	font-size: 12px !important;
}

.iconTransaccApro {
	font-size: 17px;
	color: #50bd50;
}

.iconTransaccFall {
	font-size: 17px;
	color: #ac4345
}

.ItemResumen {
	padding: 20px;
	padding-left: 15px;
	color: #fff;
	margin-left: 20px;
	margin-bottom: 20px;
}

.Azul {
	background-color: #1d65f4 !important;
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
}

.FechasFiltro {
	display: flex;
	background-color: #123f98;
	color: #fff;
	font-weight: 400;
	border-radius: 20px;
	margin-top: 17px;
	padding: 10px 20px;
	width: auto;
}

.headReserva {
	background-color: #1d65f4 !important;
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	;
	padding: 20px;
}

.headReserva {
	color: #fff;
}

.columaReserva {
	padding: 20px;
}

.columaReserva p,
.ItemResumen p {
	margin-bottom: 0px;
}

.columaReserva h5,
.ItemResumen h2 {
	margin-bottom: 0px;

}

.FechaRerserva {
	/* background-color: #fff;
	padding: 10px;
	border-radius: 20px;
	color: #333;
	margin-bottom: 5px; */
	font-size: 20px;
}

.headReserva h2 {
	margin-bottom: 0px;
}

.Calendario {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border: none !important;
	border-radius: 30px;
	width: 100% !important;
	overflow: hidden;
	margin-bottom: 15px;
}

.react-calendar__viewContainer {
	padding: 10px;
	padding-bottom: 20px;
}


.highlight {
	color: rgb(47, 47, 47);
	background-color: rgb(255, 115, 115) !important;
	padding: 10px !important;
}

.TablaResumen,
.ContadorResumen {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 30px;
	width: 100% !important;
	overflow: hidden;
	transition: all .3s ease-in-out;
}

.ContadorResumen:last-child {
	margin-top: 15px;
}

.transformar {
	display: block;
	height: 110px;
	transition: height 200s, transform 2s;
	/* transition: max-height 0.45s ease-in; */
}

.HeaderTablaResumen {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	padding: 10px 15px 5px 20px;
}

.HeaderTablaResumen h3 {
	color: #fff;
	font-weight: bold;
}

.HeaderTablaResumen h4 {
	color: #fff;
	font-weight: 200;
	font-size: 18px;
	margin-top: 4px;
}

.CuerpoTablaResumen {
	padding: 20px;
}

.CuerpoTablaResumen table {
	width: 100%;
	font-weight: bold;
}

.CuerpoTablaResumen table td {
	font-weight: bold;
}

.precioTableResumen {
	color: rgba(29, 101, 244, 1);
}

.totalTableResumen {
	color: rgba(29, 101, 244, 1);
	font-size: 18px;
}

.ContadorResumen p {
	margin: 0px;
}

.ContadorResumen h5 {
	padding-left: 10px;
	text-align: center;
	margin-top: 10px;
}

.botonContadorResumen {
	width: 100%;
	height: 100%;
	border: none;
	font-size: 20px;
	background-color: white;
	color: #111;
}

.botonContadorResumen:hover {
	background-color: rgb(239, 239, 239);
}



.TextAzul {
	color: rgba(29, 101, 244, 1);
}

.ContadorResumen hr {
	margin: 0px;

}

.HeaderContadorResumen {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	padding: 10px 15px 5px 20px;
	width: 100%;
	border: none;
	margin-bottom: 1px;
}

.HeaderContadorResumen h6 {
	color: #fff;
	font-weight: 400;
}

.SelectIngreso {
	width: 100%;
	border: none;
	border-radius: 30px;
	height: 55px;
	background-color: transparent;
	color: #fff !important;
}

.SelectIngreso:focus-visible,
.botonContadorResumen:focus {
	outline: none !important;
	border: none !important;
}

.SelectIngreso option {
	color: #111;
}

.ContSelectIngreso {
	padding: 0px 10px !important;
	width: 100%;
	border: none;
	border-radius: 30px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	height: 55px;
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	color: #fff !important;
}

.CampoPersonas {
	background-color: #f9f9f9;
	padding: 20px 10px 20px 20px;
	border-radius: 20px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin-bottom: 20px;
}

.container-campo-personas {
	position: relative;
}

.BtnAddImagenTrash {
	position: absolute;
	height: 30px;
	width: 30px;
	top: -7px;
	right: -3px;
	z-index: 1;
	bottom: auto;
	margin-right: 0;
	border-radius: 20%;
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	border: none
}

.container-guia {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin: 6px;
	width: 98%;
	padding: 6px;
	overflow: auto;
}

.icon-remove {
	font-size: 13px;
	color: white;
}

.CampoPersonas input {
	background-color: #fff !important;
	margin: 0px 0px !important;
	height: 10px;
}

.CampoPersonas .md-form {
	margin: 8px 0px !important;
}

.CampoPersonas h4 {
	margin: 0px;
	border-radius: 20px;
}

.CampoPersonas input,
.CampoPersonas select {
	height: 35px !important;
	font-size: 5 !important;
}

.CampoServicios {
	background-color: #f9f9f9;
	border-radius: 20px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	margin-bottom: 20px;
	margin-left: -15px;
	margin-right: -15px;
	border-radius: 20px;
	overflow: hidden;
}

.filtering .react-calendar__month-view {
	opacity: 0.5;
}

.CampoServicios .HeaderTablaServicios {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	padding: 15px 20px;
	font-size: 1rem;
	color: #fff;
	font-weight: normal;
}

.CampoServicios .servicio-item {
	padding: 10px 20px;

	border-radius: 15px;
}

.CampoServicios .servicio-item .guides-selector ul {
	list-style: none;
	padding: 15px;
	margin: 10px 0 0;

	background: #BDCDD6;
	border-radius: 20px;
}

.CampoServicios .servicio-item .guides-selector ul li {
	cursor: pointer;
	border-bottom: 1px solid darkgray;
	padding: 5px 0;
}

.CampoServicios .servicio-item .guides-selector ul li:hover {
	background-color: #EEE9DA;
}

.CampoServicios .servicio-item .servicio-item_container {
	display: flex;
	justify-content: space-around;
}

.CampoServicios .servicio-item .servicio-item_container img {
	object-fit: cover;
}

.CampoServicios .servicio-item .servicio-item_container .servicio-image {
	margin-right: 10px;
}

.CampoServicios .servicio-item .servicio-item_container .servicio-description .nombre {
	font-size: 15px;
	line-height: 15px;

	margin: 0;
	padding: 0;
}

.CampoServicios .servicio-item .servicio-item_container .servicio-description .instrucciones {
	font-size: 13px;
	padding: 0;
	margin: 0;
}

.CampoServicios .servicio-item .servicio-item_container .servicio-description .precio {
	font-size: 20px;

	margin: 0;
	padding: 0;
}

.CampoServicios .servicio-item .selector {
	display: flex;
	flex: 5;

	margin-top: 10px;
}

.CampoServicios .servicio-item .selector .selector-btn .botonContadorResumen {
	line-height: 15px;
}

.CampoServicios .servicio-item .selector .selector-number {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-color: #E9E8E8;
	border-radius: 30px;
}

.CampoServicios .addGuianzaBtn {
	font-size: 25px;
	color: white;

	width: 50px;
	height: 50px;
	border: 0;
	border-radius: 50%;
	background-color: rgb(29, 101, 244);

	align-self: center;
	justify-self: center;

	margin: 0 0 20px 20px;
}

.ItemAddServicio p {
	margin: 0px;
	font-size: 13px;
	margin-left: 5px;
}

.ItemAddServicio label:before,
.ItemAddServicio label:after {
	height: 25px;
	width: 25px;
	font-size: 20
}

.TabHeaderServicio {
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	color: #fff !important;
	border-radius: 5px;
	padding: 8px;
	width: 100%;
	border: none;
	margin-bottom: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.TabHeaderServicio h6 {
	margin: 0px;
}

.TabBodyServicio {
	padding: 3px;
	background-color: #ffffff;
}

.alerta-calendario {
	display: flex;
	justify-content: center;
}

.hospedaje-item {
	height: 105px;
	align-items: center;
	text-align: center;
}

.servicios-alimentacion-dias {
	border-radius: 50%;
}

.servicios-alimentacion-dias span {
	font-size: 20px;
	font-weight: 400;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.servicios-alimentacion-cupos {
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.servicios-alimentacion-cupos span {
	font-size: 20px;
	font-weight: 400;
}

.servicios-alimentacion-cupos div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35px;
	width: 35px;
	border-radius: 35%;
}

.servicios-alimentacion {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 10px;

}


.header-servicios-alimentacion {
	display: flex;
	flex: 1;
	justify-content: end;
	flex-direction: column;
}

.servicios-alimentacion-container {
	overflow: auto;
	padding: 0;
}


.btn-primary {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	background-color: #1d65f4;
}

.btn-secondary {
	background-color: #5a6268 !important;
}

@media print {
	.pagebreak {
		page-break-before: always;
	}

	@page {
		size: auto !important;
	}
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
	.barra_izquierda {
		height: auto !important;
	}

	html {
		overflow-y: scroll;
	}
}

html {
	overflow-y: scroll;
}


.disable-all {
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}

.cursor {
	cursor: pointer;
}

.is-invalid {
	border: 2px solid red !important;
}


/* width */
.servicios-alimentacion-container::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
.servicios-alimentacion-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	;
	border-radius: 0px;
}

/* Handle */
.servicios-alimentacion-container::-webkit-scrollbar-thumb {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	;
	border-radius: 10px;
}

/* Handle on hover */
.servicios-alimentacion-container::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);

}


.MuiPaper-root {
	box-shadow: none !important;
	font-size: .9rem;
}

.MuiTableCell-root {
	font-size: .9rem;
}

.MuiButtonBase-root {
	padding: 0 !important
}

.informes-fechas {
	display: flex;
	align-items: center;
	background: #1674EF;
	margin-right: 10px;
	padding: 7px 20px;
	border-radius: 60px;
	color: #ffffff;
	font-weight: bold;
	font-size: 15px;
}

.informes-fechas label {
	margin: 0;
}

.informes-fechas-input {
	position: relative;
	background: #1674EF;
	border: none;
	color: #ffffff;
	width: 116px;
	margin-left: 3px;
	font-weight: bold;
}

.informes-fechas-input::-webkit-calendar-picker-indicator {
	color: transparent;
	background: none;
	z-index: 1;
}

.informes-fechas-input:before {
	color: transparent;
	background: none;
	display: block;
	font-family: "Font Awesome 5 Free";
	font-weight: 100;
	content: "\f073";
	/* This is the calendar icon in FontAwesome */
	width: 15px;
	height: 20px;
	position: absolute;
	right: 6px;
	color: #ffff;
}

.informes-zonas {
	margin-top: 30px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 10px;
	display: flex;
	overflow: hidden;
}

.informes-zonas_linea {
	background-color: #2772CE;
	width: 4px;
}



/* width */
.informes-zonas_container::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
.informes-zonas_container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	border-radius: 0px;
}

/* Handle */
.informes-zonas_container::-webkit-scrollbar-thumb {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);

	border-radius: 10px;
}

/* Handle on hover */
.informes-zonas_container::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);

}

.informes-zonas_container {
	padding: 10px 25px 25px 25px;
	display: flex;
	flex-direction: column;
	overflow-x: scroll;
	width: 100%;
}

.informes-zonas_container_item {
	display: flex;
}

.informes-zonas_container_item_informacion {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 10px;
	overflow: hidden;
	margin-right: 20px;
	display: flex;
	flex-direction: column;
	min-width: 120px;
}

.informes-zonas_container_item_informacion_cantidad {
	padding: 5px 10px;
	text-align: center;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
}

.informes-zonas_container_item_informacion_cantidad p {
	padding: 0;
	margin: 0;
}

.informes-zonas_container_item_informacion_linea {
	height: 3px;
}

.informes-zonas_container_item_informacion_cantidad_total {
	font-size: 10px;
}

.informes-zonas_container_item_informacion_cantidad_parrafo {
	font-weight: 400;
}

.informes-zonas_container_item_informacion_cantidad_parrafo span {
	font-weight: 400;
	font-size: 20px;
}

button:focus {
	outline: none
}

.reserva-taquilla {
	min-width: 50%;
	max-width: 50%;
	margin: 10px;
}

.reserva-taquilla-item {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 30px;
	padding: 10px;
}

.reserva-taquilla-informacion {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 30px;
	padding: 10px;
	overflow: auto;
	max-height: 800px;
}

.reserva-taquilla-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;

}

.reserva-taquilla-container .nav-ingreso {
	margin-bottom: 10px;
}

.reserva-taquilla-container .nav-ingreso .ingreso {
	background-color: #C2C2C2;
	padding: 10px 70px;
	border-radius: 30px 0px 0px 30px;
}

.reserva-taquilla-container .nav-ingreso .compra {
	background-color: #C2C2C2;
	padding: 10px 70px;
	border-radius: 0px 30px 30px 0px;
}

.reserva-taquilla-container .nav-ingreso .nav-item a {
	color: #ffffff;
	font-weight: bold;
}

.reserva-taquilla-container .nav-ingreso .nav-item {
	flex: 1;
	text-align: center;
}

.reserva-taquilla-container .nav-ingreso .active {
	background-color: #1A5AD8;
}


.input_autocomplete_input {
	border-radius: 10px;
	margin-bottom: 20px;
}

.input_autocomplete_data-encontrada {
	max-height: 400px;
	overflow: auto;
	padding: 5px;
}

.input_autocomplete_data-encontrada .input_autocomplete_data-item {
	padding: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	border-bottom: 1px solid #d4d4d4;
	background-color: #1A5AD8;
	border-radius: 10px;
	display: flex;
}


/* width */
.input_autocomplete_data-encontrada::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
.input_autocomplete_data-encontrada::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	border-radius: 0px;
}

/* Handle */
.input_autocomplete_data-encontrada::-webkit-scrollbar-thumb {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);

	border-radius: 10px;
}

/* Handle on hover */
.input_autocomplete_data-encontrada::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);

}

.input_autocomplete_data-item-reserva {
	color: #ffffff;
	display: flex;
	flex-direction: column;
	flex: 4;
	font-size: 13px;
}

.input_autocomplete_data-item-reserva h2 {
	font-size: 23px;
	margin-top: 2px
}

.input_autocomplete_data-item-image img {
	max-width: 82px;
	object-fit: cover;
}

.input_autocomplete_data-item-image {
	flex: 1;
	display: flex;
	align-items: center;
}

.informacionReserva {
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	padding: 10px;
	color: #ffffff;
	border-radius: 10px;
	display: flex;
	font-size: 15px;
}

.informacionReserva h2 {
	font-size: 23px;
	margin: 0;
	margin-top: 2px
}

.boton_estandar-icon {
	display: flex;
	justify-content: center;
	font-size: 18px;
	text-transform: none;
	align-items: center;
}

.boton_estandar-icon p {
	margin-bottom: 0;
	margin-left: 15px;
}

.informacion-reserva-btn {
	display: flex;
	justify-content: space-between;
}

.informacion-reserva-btn .boton_estandar-icon {
	flex: 1;
}

.boton_estandar-visitantes {
	flex: 1;
	padding: 8px;
	display: flex;
	background-color: #65c895;
	border-radius: 25px;
	color: #ffff;
	justify-content: center;
	font-size: 18px;
	align-items: center;
}

.boton_estandar-visitantes p {
	margin-bottom: 0;
	margin-right: 15px;
}

.boton_estandar-visitantes:hover {
	opacity: 0.8;
	color: #fff;
	background-color: #65c895;
}

.btn-icon {
	font-size: 25px;
}

.reserva-taquilla-view {
	display: flex;
}

.compra-total {
	display: flex;
	justify-content: space-between;

}

.compra-total p {
	font-size: 25px;
}

.compra-total-servicios {
	display: flex;
	justify-content: space-between;

}

.compra-total-servicios p {
	font-size: 13px;
	margin-bottom: 3px;
}

.estado-reservas {
	margin-left: 5px;
}

.compra-total-dinero-item {
	display: flex;
	padding: 8px 10px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	color: #72916E;
	border: 1px solid #72916E;
	margin-right: 10px;
	margin-top: 10px;
	cursor: pointer;
}

.compra-total-dinero-item:hover {
	background-color: #72916E;
	color: #ffff;
}

.compra-total-dinero-item p {
	padding: 0;
	margin: 0;
	margin-left: 5px;
}

.compra-total-dinero-container {
	flex: 2;
	display: flex;
	flex-wrap: wrap;

}

.compra-total-dinero-regresar {
	display: flex;
	align-items: center;
	justify-content: center;
}

.compra-total-dinero {
	display: flex;
}

.compra-total-dinero-regresar-item {
	display: flex;
	color: red;
	border: 1px solid red;
	padding: 30px 15px;
	border-radius: 10px;
	font-size: 23px;
	cursor: pointer;
}

.compra-total-dinero-regresar-item:hover {
	background-color: red;
	color: #ffff;
}

.compra-container {
	display: flex;
	flex-direction: column;
}

.item-amarillo {
	color: #B2A632;
	border: 1px solid;
}

.item-amarillo:hover {
	background-color: #B2A632;
}

.compra-total p {
	margin: 0px;
}

.compra-total-input {
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #ced4da;
	font-size: 18px;
	margin-top: 5px;
}

.boton_estandar-visitantes-crear {
	flex: 1;
	padding: 8px;
	display: flex;
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	border-radius: 25px;
	color: #ffff;
	justify-content: center;
	font-size: 18px;
	align-items: center;
	margin-top: 15px;
}

.boton_estandar-visitantes-crear p {
	margin-bottom: 0;
	margin-left: 15px;
}

.boton_estandar-visitantes-crear:hover {
	opacity: 0.8;
	color: #fff;
}

.modal-compra-pos .modal-dialog .modal-content .modal-title {
	font-weight: 100;
}

.modal-compra-pos .modal-dialog .modal-content .salir-modal {
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	border-radius: 25px;
	color: #ffffff;
	border: none;
	padding: 10px 20px;
}

.modal-compra-pos .modal-dialog .modal-content .modal-footer button {
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	border-radius: 25px;
	color: #ffffff;
	border: none;
	padding: 10px 20px;
	flex: 1;
}

.modal-compra-pos .modal-dialog .modal-content .modal-body {
	padding: 0;
}

.modal-compra-pos-input {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #dee2e6;
	padding: 10px;
	font-size: 17px;
	align-items: center;
}

.modal-compra-pos-input .form-check-input {
	font-size: 35px;
	position: relative;
}

.react-calendar-container {
	max-width: 600px;
	margin: auto;
	padding: 10px;
	border-radius: 3px;
}

.react-calendar__navigation button {
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	color: #fff !important;
	height: 54px !important;
	border: none;
}

.react-calendar__navigation {
	display: flex;
}

.react-calendar__navigation__label {
	font-weight: bold;
}

.react-calendar__navigation__arrow {
	flex-grow: 0.333;
}

.react-calendar__month-view__weekdays {
	text-align: center;
}

.react-calendar-container .react-calendar__month-view__days button {
	margin: 3px;
	width: 48px;
	height: 45px;
	background-color: white;
	border: 0;
	border-radius: 50%;
	padding: 5px 0;
}


.react-calendar-container .react-calendar__month-view__days button:hover {
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	color: #ffffff;
}


.react-calendar-container button:disabled {
	background: #F0F0F0;
}


.react-calendar-container .react-calendar__month-view__days__day--weekend {
	color: red;
}

.react-calendar-container .react-calendar__tile--active,
.react-calendar-container .react-calendar__tile--hasActive {
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	color: #ffffff;
}

.react-calendar__month-view__days {
	display: grid !important;
	grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
	grid-gap: 0px;
	justify-items: center;
	align-items: center;
}

.react-calendar__tile {
	max-width: initial !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
	text-decoration: none;
	text-transform: uppercase;
	font-weight: bold;
}

.informacion-reserva-imagen {
	display: flex;
}

.informacion-reserva-imagen img {
	width: 50px;
	height: 50px;
	margin-right: 15px;
}

.servicios-guianza-nota {
	font-weight: 300;
	text-align: center;
	font-size: 13px;
}

.MuiToolbar-root p {
	margin-bottom: 0px;
}

.icon-btn {
	margin-left: 4px;
}

.CSVImporter_Importer .CSVImporter_TextButton {
	background-color: #1d65f4;
	border-radius: 25px;
	background: rgb(29, 101, 244);
	background: linear-gradient(36deg, rgba(29, 101, 244, 1) 0%, rgba(21, 74, 180, 1) 100%);
	color: #fff;
	padding: 10px 20px;
}


.CSVImporter_Importer .CSVImporter_TextButton:hover {
	background-color: #1d65f4 !important;
	opacity: 0.8;
	color: #fff;
}

.CSVImporter_Importer .CSVImporter_FormatRawPreview__pre {
	color: #ffffff;
	font-size: 17px;
	height: 100%;
}

.ticketPrint {
	width: 440px;
}

.ticketPrint .codeQR {
	display: flex;
	flex-direction: column;
	padding: 20px;
	margin-bottom: 10px;
}

.spinner {
	display: flex;
	justify-content: center;
}

.spinner .fa-spinner {
	color: rgb(29, 101, 244);
}

.totales-reservas {
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
	flex-wrap: wrap;
}


.total-saldo-pendiente {
	background-color: #F5BFBD;
}

.total-saldo-favor {
	background-color: #DBF9BB;
}

.container-totales-reservas {
	flex: 1;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 20px;
	padding: 13px;
	margin: 10px;
}

.container-totales-reservas p {
	margin: 0;
}

.container-totales-reservas p b {
	font-weight: bold;
}

.container-reservas-totales {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	border-radius: 20px;
	padding: 13px;
	margin: 3px;
	width: 150px;
}

.container-reservas-totales p {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	text-transform: uppercase;
	margin: 0;
	font-size: 11px;
}

.container-reservas-totales p b {
	font-weight: bold;
}

.tickets-elipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.MuiToolbar-gutters {
	padding: 0 !important;
}

.MuiSvgIcon-root {
	margin-left: 10px;
}

.tickets-notificacion-navegacion {
	display: flex;
	position: relative;
}

.tickets-notificacion-navegacion-icon {
	position: absolute;
	left: 90px;
	top: 8px;
	background-color: #E24160;
	border-radius: 100%;
	padding: 13px;
	width: 10px;
	height: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tickets-notificacion-navegacion-icon p {
	margin: 0;
	color: #fff;
	font-weight: bold;
	font-size: 13px;
}

.crear-usuario-visitantes .md-outline {
	margin-top: 5px;
	margin-bottom: 0px;
}

.crear-usuario-visitantes .CampoServicios {
	justify-content: center;
	padding-bottom: 10px;
}

.imagen-reserva-img {
	display: flex;
	justify-content: center;
}

.imagen-reserva-img img {
	width: 300px;
}

.tickets-estados {
	display: flex;
	align-items: center;
}

.tickets-estados .tickets-estados-button {
	margin-left: 5px;
	width: 15px;
	height: 15px;
	background-color: red;
	border-radius: 100%;
}

.input-ticket {
	border: none !important;
}

.select-ticket {
	padding-left: 23px
}

.select-ticket label {
	font-weight: 500;
	background: #fff;
	font-size: 13px;
	color: #757575;
	cursor: text;
	margin: 0;
	padding: 0;
}

.input-escondido {
	padding: 0;
	margin: 0;
	width: 0;
	height: 0px !important;
	border: none;
}

.link-ingreso-visitante span {
	color: #123f98;
	font-weight: 500;
	cursor: pointer;
}

.login-links-alternos-logo {
	display: flex;
	justify-content: center;
}

.login-links-alternos-logo p {
	margin-top: 2px;
	font-size: 15px;
	color: #999999;
}

.login-links-alternos-logo img {
	width: 150px;
	object-fit: cover;
}


.login-links-alternos {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.login-links-alternos a {
	flex: 1;
}

.login-links-alternos .boton_estandar {
	margin-bottom: 13px !important;
}

.login-links-alternos-navegacion {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.login-links-alternos-navegacion p {
	font-size: 15px;
	color: #999999;
}

.login-links-alternos-navegacion img {
	width: 120px;
	height: 36px;
	margin-left: 5px;
}

.texto-parrafo-informativa {
	color: #ffffff;
	font-size: 12px;
}